<template>
  <div class="artfTerms">
    <div class="container"> 
      <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
      <div class="row top-buffer">
          <div class="col-md-8">
          <ol class="breadcrumb">
              <li>
              <a href="https://www.gob.mx"><i class="icon icon-home"></i></a>
              </li>
              <li><a href="https://www.gob.mx/sct">SCT</a></li>
              <li><a href="/" >Licencia Federal Digital</a></li>
              <li class="active">T&eacute;rminos y Condiciones ARTF</li>
          </ol>
          </div>
      </div>

      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../../assets/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1>Licencia Federal Digital</h1>
          <h2>Términos y Condiciones</h2>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <h3 style="margin-top:0;margin-bottom:30px;font-size: 22px;">Términos y Condiciones de Uso de la Aplicación Móvil de la Licencia Federal Digital, para los distintos modos de transporte regulados por
            la Secretaría de Comunicaciones y Transportes</h3>
          <h4>Condiciones generales</h4>
          <hr class="red">
          <p class="text-justify small-bottom-buffer">Las condiciones generales que integran la aplicación <strong>“Licencia Federal Digital”</strong>, que otorga la <strong>Secretaría de Comunicaciones y Transportes (SCT)</strong>, a través de las
            tiendas de los sistemas operativos para dispositivos móviles; IOS- App Store y ANDROID- Play Store/Google Play, serán aplicables al contenido de los términos y condiciones de uso descritas en el presente documento.</p>
          <h4>1. Definiciones</h4>
          <hr class="red">
          <p class="text-justify">En lo sucesivo se entenderá para efectos de este documento como:</p>
          <p class="text-justify"><strong>Caso fortuito o de fuerza mayor:</strong> Sucesos de la naturaleza o hechos del hombre que provocan una imposibilidad material a la SCT para ofrecer el servicio del aplicativo Licencia Federal Digital.</p>
          <p class="text-justify"><strong>Aplicación para la Licencia Federal Digital:</strong> Programa informático que permite descargar la Licencia Federal Digital en un dispositivo de telefonía celular móvil y en donde se visualiza la licencia
            correspondiente a cada modo de transporte.</p>
          <p class="text-justify"><strong>Licencia Federal Digital:</strong> Documento electrónico emitido por la Secretaría de Comunicaciones y Transportes, en el ejercicio de sus atribuciones, a través de la Dirección General de Autotransporte
            Federal, Agencia Reguladora del Transporte Ferroviario, Agencia Federal de Aeronáutica Civil y Dirección General de Puertos y Marina Mercante, que acredita las habilidades, capacidades o certificaciones, según corresponda, necesarias para
            la conducción, auxilio, operación o pilotaje de vehículos de autotransporte, ferrocarriles, aeronaves o buques.</p>
          <p class="text-justify"><strong>PIN:</strong> Contraseña de cuatro dígitos registrada en el aplicativo de Licencia Federal Digital.</p>
          <p class="text-justify"><strong>SCT:</strong> Secretaría de Comunicaciones y Transportes.</p>
          <p class="text-justify small-bottom-buffer"><strong>Usuario:</strong> El titular de la Licencia Federal Digital.</p>

          <h4>2. Términos</h4>
          <hr class="red">
          <p class="text-justify">I. El Usuario manifiesta haber solicitado a la SCT su Licencia Federal Digital descargable en su dispositivo de telefonía celular móvil.</p>
          <p class="text-justify">II. La SCT manifiesta que los datos personales recabados del Usuario serán incorporados y protegidos en sus sistemas, de conformidad las disposiciones legales, y administrativas vigentes y aplicables a la
            confidencialidad y protección de
            datos.</p>
          <p class="text-justify">III. La SCT manifiesta que no almacena, ni conoce el contenido de la clave privada de la e. firma y/o de su PIN utilizados en el aplicativo para la Licencia Federal Digital.</p>
          <p class="text-justify">IV. La SCT manifiesta que el Usuario podrá corregir y/o actualizar sus datos personales, para ello, deberá realizar el trámite que corresponda de acuerdo con el dato que se requiera cambiar o actualizar.</p>
          <p class="text-justify">V. El Usuario será el único responsable de la información y datos que integre y capture para el uso de la aplicación Licencia Federal Digital.</p>
          <p class="text-justify">VI. El Usuario reconoce y acepta que el uso de la Licencia Federal Digital está bajo su exclusiva responsabilidad, por lo que asume el compromiso de su buen uso, de conformidad con la normatividad aplicable para la
            operación, conducción, pilotaje o auxilio en la operación de los vehículos autorizados de acuerdo con la categoría o las categorías que tenga autorizadas.</p>
          <p class="text-justify">VII. El usuario contará con un PIN con el cual podrá acceder al aplicativo para descargar y mostrar su Licencia Federal Digital.</p>
          <p class="text-justify">VIII. El Usuario se obliga a realizar los trámites necesarios para mantener vigentes y resguardados los medios de autenticación de su Licencia Federal Digital, sin embargo, en caso de que la licencia sea revocada,
            suspendida o cancelada no
            podrá utilizarla en el apartado que requiera las credenciales de acceso. En caso de pérdida u olvidó del PIN, el sistema le permitirá capturar su número de expediente y registrar un nuevo PIN.</p>
          <p class="text-justify">IX. El usuario reconoce y acepta que no se genera una obligación para la SCT, de ofrecer funcionalidades distintas o adicionales a las proporcionadas por la Licencia Federal Digital.</p>
          <p class="text-justify small-bottom-buffer">X. La SCT deberá mantener la funcionalidad del aplicativo para la Licencia Federal Digital de forma permanente, pudiendo suspenderse únicamente por caso fortuito o fuerza mayor, debiendo
            informarlo oportunamente a los Usuarios.</p>

          <h4>3. Condiciones</h4>
          <hr class="red">
          <p class="text-justify">El servicio del aplicativo estará disponible una vez instalado en un dispositivo móvil con sistema operativo IOS o Android y con datos para el consumo de internet; previa operación, el usuario deberá autenticarse con
            su PIN. El aplicativo podrá operar con conexión a internet o sin ella.</p>
          <p class="text-justify small-bottom-buffer">El Usuario acepta las condiciones de operación y límites de responsabilidad de la SCT en su calidad de prestador del servicio electrónico, por lo que reconoce la aceptación de los términos y
            condiciones descritas y especificadas en este documento.</p>

          <h4>4. Modificaciones</h4>
          <hr class="red">
          <p class="text-justify">Las presentes Condiciones de Uso y Términos de la Aplicación Licencia Federal Digital de la SCT pueden sufrir modificaciones, cambios o actualizaciones con la finalidad de optimizar su operación y garantizar la
            legalidad de su uso, por lo que le mantendremos informado sobre los cambios que puedan efectuarse, a través del sitio <a href="#" target="_blank">www.nombresitio.gob.mx</a></p>

          <p class="text-justify top-buffer">Ciudad de México, a 13 de noviembre de 2020.</p>
        </div>
      </div>
      <div class="row">
          <div class="col-md-12 text-right">
            <hr />
            <a class="btn btn-default" type="button" href="/">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "artfTerms",
  data() {
    return {
    };
  },
  mounted() {
    
  },
  methods: {
    
  },

  components: {
  },
};
</script>
